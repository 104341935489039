<template>
  <b-modal
    id="modal-lottery-detail-report"
    ok-variant="outline-secondary"
    :ok-title="$t('button.close')"
    centered
    :title="$t('field.lottery')"
    size="xl"
    :hide-footer="true"
    @hide="isShow = false"
  >
    <div>
      <b-row class="pb-1">
        <b-col md="6">
          {{ $t("field.invoiceNo") }} : {{ data.invoiceNo }}
        </b-col>
        <b-col md="6">
          {{ $t("field.date") }} : {{ data.createdAt | formatDate }}
        </b-col>
        <b-col md="6">
          {{ $t("field.gameType") }} : {{ data.channelName }}
        </b-col>
        <b-col md="6">
          {{ $t("field.lottery") }} : {{ getLotteryName(data.no) }}
        </b-col>
        <b-col md="6">
          {{ $t("field.time") }} : {{ getLotteryTime(data.no) | formatTime($i18n.locale) }}
        </b-col>
        <b-col md="6">
          {{ $t("field.betAmount") }} :
          <span
            :class="{
              'text-wala': data.amount > 0,
              'text-meron': data.amount < 0,
            }"
          > {{ data.amount | currency(data.ccy) }}
          </span>
        </b-col>
        <b-col md="6">
          {{ $t("field.winLoss") }} :

          <span
            :class="{
              'text-wala': data.winAmount > 0,
              'text-meron': data.winAmount < 0,
            }"
          >
            {{ data.winAmount | currency(data.ccy) }}
          </span>
        </b-col>
        <b-col md="6">
          {{ $t("field.payback") }} :
          <span
            :class="{
              'text-wala': data.paybackAmount > 0,
              'text-meron': data.paybackAmount < 0,
            }"
          >
            {{ data.paybackAmount | currency(data.ccy) }}
          </span>
        </b-col>
        <b-col
          md="6"
          class="text-uppercase"
        >
          {{ $t("field.username") }} : {{ data.username }}
        </b-col>
      </b-row>
      <n-table
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="1"
      >
        <template #cell(amount)="{ item }">
          <span
            :class="{
              'text-wala': item.amount > 0,
              'text-meron': item.amount < 0,
            }"
          >
            {{ item.amount | currency(data.ccy) }}
          </span>
        </template>
        <template #cell(total)="{ item }">
          <span
            :class="{
              'text-wala': item.total > 0,
              'text-meron': item.total < 0,
            }"
          >
            {{ item.total | currency(data.ccy) }}
          </span>
        </template>
        <template #cell(winAmount)="{ item }">
          <span
            :class="{
              'text-wala': item.payoutAmount > 0,
              'text-meron': item.payoutAmount < 0,
            }"
          >
            {{ item.payoutAmount | currency(data.ccy) }}
          </span>
        </template>
        <template #cell(result)="{ item }">
          {{ getResult(item.result) }}
        </template>
      </n-table>
    </div>
  </b-modal>
</template>

<script>
import { BCol, BModal, BRow } from 'bootstrap-vue'
import NTable from '@/components/NTable.vue'
import { camelizeKeys } from 'humps'
import tableFields from './tableFields'

export default {
  components: {
    BModal,
    NTable,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
      items: [],
      data: {},
      isShow: false,
    }
  },
  methods: {
    show(data) {
      this.$bvModal.show('modal-lottery-detail-report')
      this.isShow = true
      this.items = []
      // eslint-disable-next-line no-restricted-syntax
      for (const item of data.detail) {
        const selected = item.selected ? camelizeKeys(JSON.parse(item.selected)) : {}
        const result = item.result ? camelizeKeys(JSON.parse(item.result)) : {}

        this.items.push({
          ...selected,
          ...result,
        })
      }
      this.data = { ...data }
    },
    hide() {
      this.$bvModal.hide('modal-lottery-detail-report')
      this.items = []
    },
    getLotteryName(str) {
      if (!str) {
        return ''
      }

      return str.match(/^[^-]+/)[0]
    },
    getLotteryTime(str) {
      if (!str) {
        return ''
      }

      const result = str.match(/-(.+)/)
      return result ? result[1] : ''
    },
    getResult(str) {
      if (!str) {
        return '-'
      }

      return str.replace(',', ', ')
    },
  },
  setup() {
    const fields = [...tableFields]

    return {
      fields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
