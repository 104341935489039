var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-lottery-detail-report","ok-variant":"outline-secondary","ok-title":_vm.$t('button.close'),"centered":"","title":_vm.$t('field.lottery'),"size":"xl","hide-footer":true},on:{"hide":function($event){_vm.isShow = false}}},[_c('div',[_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.invoiceNo"))+" : "+_vm._s(_vm.data.invoiceNo)+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.date"))+" : "+_vm._s(_vm._f("formatDate")(_vm.data.createdAt))+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.gameType"))+" : "+_vm._s(_vm.data.channelName)+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.lottery"))+" : "+_vm._s(_vm.getLotteryName(_vm.data.no))+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.time"))+" : "+_vm._s(_vm._f("formatTime")(_vm.getLotteryTime(_vm.data.no),_vm.$i18n.locale))+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.betAmount"))+" : "),_c('span',{class:{
            'text-wala': _vm.data.amount > 0,
            'text-meron': _vm.data.amount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.data.amount,_vm.data.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.winLoss"))+" : "),_c('span',{class:{
            'text-wala': _vm.data.winAmount > 0,
            'text-meron': _vm.data.winAmount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.data.winAmount,_vm.data.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.payback"))+" : "),_c('span',{class:{
            'text-wala': _vm.data.paybackAmount > 0,
            'text-meron': _vm.data.paybackAmount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.data.paybackAmount,_vm.data.ccy))+" ")])]),_c('b-col',{staticClass:"text-uppercase",attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.username"))+" : "+_vm._s(_vm.data.username)+" ")])],1),_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":1},scopedSlots:_vm._u([{key:"cell(amount)",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:{
            'text-wala': item.amount > 0,
            'text-meron': item.amount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,_vm.data.ccy))+" ")])]}},{key:"cell(total)",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:{
            'text-wala': item.total > 0,
            'text-meron': item.total < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(item.total,_vm.data.ccy))+" ")])]}},{key:"cell(winAmount)",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:{
            'text-wala': item.payoutAmount > 0,
            'text-meron': item.payoutAmount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payoutAmount,_vm.data.ccy))+" ")])]}},{key:"cell(result)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getResult(item.result))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }