export default [
  {
    key: 'post',
    label: 'field.channel',
  },
  {
    key: 'num',
    label: 'field.number',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'total',
    label: 'field.total',
  },
  {
    key: 'winAmount',
    label: 'field.winLoss',
  },
  {
    key: 'result',
    label: 'field.result',
  },
]
